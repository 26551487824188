<template>
  <v-container v-if="hasPermission">
    <mex-sperm-spinner v-if="addRoleLoading" :spinnerText="getRoleLoadingSpinnerText" />
    <template v-else>
      <mex-heading content="Add Role"></mex-heading>
      <v-row justify="center">
        <v-col cols="12">
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validateAdditionForm)">
              <mex-card icon="mdi-account-multiple-plus">
                <v-row>
                  <v-col cols="4">
                    <v-row justify="center">
                      <v-col cols="8">
                        <validation-provider v-slot="{ errors }" name="Role Name" rules="required">
                          <v-text-field
                            v-model="roleName"
                            :error-messages="errors"
                            label="Role Name"
                            outlined
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="8">
                        <v-textarea v-model="description" label="Description" outlined />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1">
                    <v-divider vertical></v-divider>
                  </v-col>
                  <v-col cols="7">
                    <v-row>
                      <validation-provider v-slot="{ errors }" name="Parent Role" rules="required">
                        <v-autocomplete
                          v-model="parentRoleID"
                          :error-messages="errors"
                          :items="rolesList"
                          item-text="name"
                          item-value="SystemRoleID"
                          outlined
                          label="Parent Role"
                          @change="parentRoleSelectionChanged"
                        />
                      </validation-provider>
                    </v-row>
                    <v-row>
                      <permission-treeview
                        :permissions="permissions"
                        :selectedPermissions="selectedPermissions"
                        :editMode="true"
                        @permissionSelectionChanged="updateMyRolePermissionArray"
                      />
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="auto">
                    <mex-btn content="Add" type="submit" />
                    <mex-btn content="Cancel" @click="cancelRoleAddition" />
                  </v-col>
                </v-row>
              </mex-card>
            </form>
          </validation-observer>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import requiredPermissions from "../../requiredPermissions";
import PermissionTreeview from "../../components/LicSrvComponents/PermissionTreeview.vue";
import SystemPermissionsService from "../../services/systemPermissions.service";
import SystemRolesService from "../../services/systemRoles.service";

export default {
  name: "EditUser.vue",
  components: {
    PermissionTreeview
  },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getRoleLoadingSpinnerText() {
      return this.addRoleLoadingText;
    }
  },
  data() {
    return {
      permissions: [],
      selectedPermissions: [],
      roleName: "",
      description: "",
      parentRoleID: null,
      rolesList: [],
      parentRolesPermissions: [],
      currentUserPermissions: [],
      hasPermission: false,
      addRoleLoading: false,
      addRoleLoadingText: '',
    };
  },
  methods: {
    getAddRoleData() {
      this.addRoleLoading = true;
      this.addRoleLoadingText = "loading parent roles";
      // ------------------------- GET THE PARENT ROLES -----------------------------
      SystemRolesService.getRolesList()
        .then(rolesResponse => {
          this.rolesList = rolesResponse.data.roles;
          this.addRoleLoadingText = "loading permissions";
          // -------------------------- GET ALL PERMISSIONS -------------------------------
          SystemPermissionsService.getAllPermissions()
            .then(allPermissionsResponse => {
              this.permissions = this.addValuesToPermissions(allPermissionsResponse.data.permissions);
              if (this.$route.params.parentRoleID) {
                // -------------------------- GET PARENT ROLE PERMISSIONS ---------------------
                this.addRoleLoadingText = "loading parent role permissions";
                this.parentRoleID = this.$route.params.parentRoleID;
              }
              if (this.$route.params.templateRoleID) {
                // -------------------------- GET TEMPLATE ROLE -------------------------
                SystemRolesService.getRoleByRoleID(this.$route.params.templateRoleID)
                  .then(templateRoleResponse => {
                    SystemRolesService.getPermissionsByRoleID(templateRoleResponse.data.role.SystemRoleID)
                      .then(templatePermissionsResponse => {
                        this.selectedPermissions = templatePermissionsResponse.data.permissions;
                        this.checkForSelectedPermissions(this.permissions);
                        this.parentRoleID = templateRoleResponse.data.role.parentRoleID;
                      })
                      .catch(err => {
                        this.addRoleLoading = false;
                        this.$toast.error(err.response.data.message);
                      });
                  })
                  .catch(err => {
                    this.addRoleLoading = false;
                    this.$toast.error(err.response.data.message);
                  });
              }
              this.addRoleLoading = false;
            })
            .catch(err => {
              this.addRoleLoading = false;
              this.$toast.error(err.response.data.message);
            });
        })
        .catch(err => {
          this.addRoleLoading = false;
          this.$toast.error(err.response.data.message);
        });
    },
    getParentRoleData() {
      if (this.parentRoleID !== -1) {
        SystemRolesService.getPermissionsByRoleID(this.parentRoleID)
          .then(parentRolePermissionsResponse => {
            this.parentRolesPermissions = parentRolePermissionsResponse.data.permissions;
            this.disablePermissions(this.permissions);
          })
          .catch(err => {
            this.$toast.error(err.response.data.message);
          });
      }
    },
    checkForSelectedPermissions(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        permissions[i].keywords.forEach(keyword => {
          if (this.selectedPermissions.includes(keyword.SystemPermissionID)) {
            keyword.checked = true;
          }
        });
        if (permissions[i].children.length !== 0) {
          this.checkForSelectedPermissions(permissions[i].children);
        }
      }
    },
    disablePermissions(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        permissions[i].keywords.forEach(keyword => {
          if (!this.parentRolesPermissions.includes(keyword.SystemPermissionID)) {
            keyword.checked = false;
            keyword.disabled = true;
          } else {
            keyword.disabled = false;
          }
        });
        if (permissions[i].children.length !== 0) {
          this.disablePermissions(permissions[i].children);
        }
      }
    },
    addValuesToPermissions(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].children.length !== 0)
          this.addValuesToPermissions(permissions[i].children);
        permissions[i].keywords.forEach(keyword => {
          keyword.disabled = false;
          keyword.checked = false;
          keyword.childrenChecked = false;
        });
      }
      return permissions;
    },
    updateMyRolePermissionArray(value) {
      this.selectedPermissions = value;
    },
    parentRoleSelectionChanged(value) {
      this.parentRoleID = value;
    },
    addNewRole() {
      SystemRolesService.addRole(
        this.roleName,
        this.description,
        this.parentRoleID,
        this.selectedPermissions
      )
        .then(() => {
          this.$toast.success("New Role Added");
          this.$router.push({ name: "RolesOverview" });
        })
        .catch(err => {
          this.$toast.error(`Error while adding new role: ${err.response.data.message}`);
        });
    },
    cancelRoleAddition() {
      this.$router.push({ name: "RolesOverview" });
    },
    validateAdditionForm() {
      this.addNewRole();
    }
  },
  watch: {
    parentRoleID: {
      handler() {
        this.getParentRoleData();
      }
    }
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.addRole, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.getAddRoleData();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  }
};
</script>

<style scoped></style>
